import React, { useState, useEffect } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import Footer from '../components/footer';
import Menu from '../components/Menu';
import Scroller from '../components/Scroller';
import logo from '../images/logo_Final-01.svg';
import './Home.css';


function Home() {
    useEffect(() =>{
        let lstner = window.addEventListener('scroll', scrollCheck, false);
        
        //cleanup
        return () => {
            lstner.removeEventListener();
        }
    }, []);

    const scrollCheck = (e) => {
        var measure = document.getElementById("banner").offsetHeight;
        if (window.scrollY >= measure) {
          var elTop = document.querySelector(".top");
          if(elTop !== null && elTop !== undefined) elTop.className = "scroll";
        }
        if (window.scrollY <= measure) {
          var elScroll = document.querySelector(".scroll");
          if(elScroll !== null && elScroll !== undefined) elScroll.className = "top";
        }
      }

  return (
    <>
    <div className="top">
      <div className="main-header" id={"banner"}>
      <section className={"header"}>
        <img src={logo} className={"gpp-logo"} />
      </section>
      <Scroller />
      </div>
      <section id={"menu"}>
        <Container><Menu home /></Container>
      </section>
      <section className={"intro first"} id={"about"}>
        <p></p>
                <h2 className={"quote"} id={"homeQuote"}>
                    <span className={"quo"}>Helping you reach your full potential!</span>
                </h2> 
        <Container>
                <Row>
              <Col sm={12} md={6}>
                <p>At Greater Potential we believe you are the most important part of your recovery and follow
evidenced based therapy to help you achieve your goals. </p>
<p>With a balance of hands-on therapy, exercise guidance and education we aim to help those in Wagga Wagga and surrounds achieve their greater potential. We take the
time to listen and help you understand the way forward.</p>
              </Col>
              
              <Col sm={12} md={6}>
              <p>With experience across the scope of physiotherapy, our service is well equiped to help you with your needs. Greater potential is here to help you recover and to show you how to develop and build your best self.</p>
              </Col>
            </Row>
        </Container>
               
        </section>
     
        

        <section className={"about backbar"} >
          <Container>
            <Row><h2>Your experience</h2></Row>
            <Row>
                <p>During appointments we take the time to listen. On an initial appointment we will provide an in-depth assessment of your injury or concern. With your goals as the focus, we will develop
a treatment plan to speed up your return to what it
is you love, feeling the way you should.</p>

<p>Our sessions will be a balance of hands-on therapy, education and home exercises to promote a
smooth recovery. We liase closely with other health professionals and those who aid in your care to get the best outcome for you.</p>
              
            </Row>
          </Container>
        </section>
        <div className={"sectionBottom aboutBlock"}></div>

        <section className={"services"} id={"services"}>
          <Container>
            <Row><h2>Services</h2></Row>
            <Row>
                <Col sm={12} md={6}>
                    <ul>
                        <li><em><a href="/services/#Age_Care">Aged care</a></em></li>
                        <li><em><a href="/services/#arthritis">Arthritis care</a></em></li>
                        <li><em><a href="/services/#balance">Balance training and falls prevention</a></em></li>
                        <li><em><a href="/services/#Cancer">Cancer rehabilitation</a></em></li>
                        <li><em><a href="/services/#Casting_and_splinting">Casting and splinting</a></em></li>
                        <li><em><a href="/services/#NDIS">Disability (NDIS)</a></em></li>
                        <li><em><a href="/services/#Dry_needling">Dry needling</a></em></li>
                        <li><em><a href="/services/#DVA">DVA (Veteran Affairs)</a></em></li>
                        <li><em><a href="/services/#Home_visit">Home visits / Mobile physiotherapy</a></em></li>
                        <li><em><a href="/services/#MSK">Musculoskeletal</a></em></li>
                    </ul>
                </Col>
                <Col sm={12} md={6}>
                    <ul>
                        <li><em><a href="/services/#orthothopaedics">Orthopaedics</a></em></li>
                        <li><em><a href="/services/#paediatrics">Paediatrics</a></em></li>
                        <li><em><a href="/services/#pain">Pain management</a></em></li>
                        <li><em><a href="/services/#sports_injuries">Sports injuries</a></em></li>
                        <li><em><a href="/services/#Strength_and_Conditioning">Strength and conditioning</a></em></li>
                        <li><em><a href="/services/#rehab">Rehabilitation</a></em></li>
                        <li><em><a href="/services/#tele">Telehealth</a></em></li>
                        <li><em><a href="/services/#workcomp">Worker's compensation</a></em></li>
                    </ul>  
                </Col>
            </Row>
          </Container>
        </section>
    </div>
    <Footer />
    </>
  );
}

export default Home;
