import React from 'react';
import { NavLink } from 'react-bootstrap';
import './Scroller.css';
  
  function Scroller() {
    return (
        <div id={"scroller"}><NavLink href="#about">⌄</NavLink></div>
    );
  }
  
  export default Scroller;
  