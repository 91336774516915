import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import Menu from '../components/Menu'
import Footer from '../components/footer';
import './Home.css';
  
  function Practice() {
    return (
      <>
        <div className="scroll">
            <section id={"menu"}>
                <Container><Menu /></Container>
            </section>
            <section className={"backbar first"}>
            <Container>
                <Row><h2>Our Practitioners</h2></Row>
            </Container>
            </section>
        <section className={"tim"}  id={"tim"}>
          <Container>
            <Row><h2>Tim Sempf - APA Sports and Exercise Physiotherapist</h2></Row>
            <Row>  
              <Col sm={12} md={5}>
              <img src="/images/Profile_tim_v1.jpg" alt="Tim head shot" width="100%" className="photo-effect" />
              </Col>
              <Col>
                <p>Has over 13 years of experience in physiotherapy and enjoys helping others reach their goals. He has extensive experience across the scope of physiotherapy. Tim graduated from the University of Canberra with a Masters of Physiotherapy and started his career in Canberra working in hospital. He has covered 
                 contact and non contact sports from amature to national level, as well as had involment with profesional athletes along his journey.</p>
                <p>He has played most team sports that Australia has to offer and has tried his hand at some solo sports. He has a keen interest in strength and conditioning, running, mountain biking, hiking and other outdoor activities. His current sport of choice is touch football where his team refer to him as zippy.</p>
                <p>Tim is a family man who enjoys being a father to his young son and daughter, he appreciates helping his wife achieve her athletic and health potential aiding her through masters qualifications during her later pregnancy and first year of their sons life</p>
                <p>If you want to know more about Tim Sempf, come have a yarn.</p>
              </Col>
            </Row>
          </Container>
        </section>
        </div>
        <Footer />
        </>
    );
  }
  
  export default Practice;
  