import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import Footer from '../components/footer';
import Menu from '../components/Menu'
import './Contact.css';
  
  function Contact() {
    return (
      <>
        <div className="scroll">
            <section id={"menu"}>
                <Container><Menu /></Container>
            </section>
            <section className={"backbar first"}>
            <Container>
                <Row><h2>Contact Us</h2></Row>
            </Container>
            </section>
        <section className={"tim"}  id={"tim"}>
          <Container>
            <Row>  
              <Col>
              <p></p>
              <p></p>
                <div className='tagline'><p>Thank you for considering Greater Potential Physio for your care. If you would like to book an appointment, please call, text or email.</p>
              </div>
              <p></p>
              <p></p>
              <p><b>PH: <a href = "tell:0437483993">0437483993</a></b></p>
              <p></p>           
               <div class ="b"><p>40 Morrow Street, Wagga Wagga, NSW 2650 (Within Body Compass Health)</p></div>
                <p></p>
              <p>Email: <a href= "mailto:greaterpotentialphysio@gmail.com">greaterpotentialphysio@gmail.com</a></p>
              </Col>
            </Row>
          </Container>
        </section>
        </div>
        <Footer />
        </>
    );
  }
  
  export default Contact;
  