import React, { useState } from 'react';
import {
    Collapse,
    Navbar,
    Nav,
    NavItem,
    NavLink,
    NavbarText
  } from 'react-bootstrap';
  import logo from '../images/logo_Final-01.svg';

  const Menu = (props) => {
    const [isOpen, setIsOpen] = useState(false);
  
    const toggle = () => setIsOpen(!isOpen);
  
    const homeLinks = () => {
        return (
          <>
            <NavItem><NavLink href="#about">About</NavLink></NavItem>
            <NavItem><NavLink href="/services">Services</NavLink></NavItem>
            <NavItem><NavLink href="/practice">Our team</NavLink></NavItem>
            <NavItem><NavLink href="/contact">Contact</NavLink></NavItem>
            <div className="social-links">
              <a href="https://www.facebook.com/GreaterPotentialPhysio?mibextid=LQQJ4d" target="_blank"><img src="/images/facebook_logo2.png" alt="Facebook" className="social-links"/></a>
              <a href="https://www.instagram.com/greaterpotentialphysio" target="_blank"><img src="/images/insta_logo2.png" alt="instagram" className="social-links"/></a>
            </div>
          </>
          
        );
    }

    const pageLinks = () => {
      return (
        <>
          <NavItem><NavLink href="/#about">About</NavLink></NavItem>
          <NavItem><NavLink href="/services">Services</NavLink></NavItem>
          <NavItem><NavLink href="/practice">Our team</NavLink></NavItem>
          <NavItem><NavLink href="/contact">Contact</NavLink></NavItem>
          <div className="social-links"><a href="https://www.facebook.com/GreaterPotentialPhysio?mibextid=LQQJ4d" target="_blank"><img src="/images/facebook_logo2.png" alt="Facebook" className="social-links"/></a>
          <a href="https://www.instagram.com/greaterpotentialphysio" target="_blank"><img src="/images/insta_logo2.png" alt="instagram" className="social-links"/></a></div>
        </>
      );
  }
  
    return (
      <div>
        <Navbar color="faded" expand="md" expanded={isOpen}>
          <Navbar.Brand href={(props.home) ? "#banner" : "/"}><img src={logo} width="100" /></Navbar.Brand>
          <Navbar.Toggle onClick={toggle} aria-controls="basic-navbar-nav" />
          <Navbar.Collapse>
            <Nav className="mr-auto navbar-full">
                { (props.home) ? homeLinks() : pageLinks() }
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    )
        
  }
  
      
  export default Menu;