import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import Footer from '../components/footer';
import Menu from '../components/Menu'
import './Home.css';

function Services() {
  return (
    <>
    <div className="scroll">
        <section id={"menu"}>
            <Container><Menu /></Container>
        </section>
        <section className={"backbar first"}>
        <Container>
            <Row><h2>Services</h2></Row>
        </Container>
        </section>

        <section className={"services"} id={"services"}>
          <Container>
            <Row>
                <p>Physiotherapists are highly skill health professionals that have the ability to help those across all walks and times of life. Physiotherapist services can encompass:</p>
                <ul class="customIndent">
                <p><li>Massage or soft tissue release</li>
                    <li>Joint mobilisations and manipulations</li>
                    <li>Advice and education regarding living well, recovering from injury and exercise and activities to keep you well</li>
                    <li>Exercise prescription</li>
                    <li>Dry needling</li>
                    <li>Pain management education and hand on techniques to aid pain relief</li>
                </p>
                <p id={"Age_Care"}/>
                </ul>
                <p>Please scroll down to see the list of services that Greater Potential Physiotherapy can help with. If you want to know more about how we can help, please <a href= "/Contact">get in touch.</a></p>
            </Row>
            <Row>
                 <Col>
                   <img src="/images/age_care.jpg" alt="eldery support" width="100%" className="photo-effect"/>
                </Col>
                    <Col>
                    <Row><h3>Age care</h3></Row>
                    <Row>
                    <p>At Greater Potential Physio, we tailor our aged care treatments to help our clients maintain as independent a lifestyle as possible during their older years through to palliative (end of life) care. We acknowledge optimal results come for a team-based approach where we work closely with our clients' treating team. </p>
                    <p>Our expert knowledge in mobility, manual handling, pathology and strength conditioning, enable our physiotherapists to work with and help educate the individual as well as their carers, minimising the risk of injury to clients and carers.</p>
                    <p id={"arthritis"}></p>
                    </Row>
                    </Col>
                </Row>
                <Row>
                    <Col className="room">
                    <Row><h3>Arthritis care</h3></Row>
                    <Row>
                    <p>Arthritis can commonly lead to joint pain, stiffness and reduced function.</p>
                    <p>Physiotherapy has shown to be affective in aiding people improve their quality of life and well being when dealing with arthritis. A well rounded personalised exercise program along with improved understanding of your condition is vital to live your best life.</p>
                    </Row>
                    </Col>
                    <Col>
                   <img src="/images/knee X-ray-2.jpg" alt="knee X-ray" width="100%" className="photo-effect" />
                    </Col>
                </Row>
            <Row id={"balance"}>
                <Col>
                <img src="/images/balance_small.jpg" alt="Falls prevention exercise" width="100%" className="photo-effect"/>
                </Col>
                <Col className="room">
                <Row><h3>Balance training and falls prevention</h3></Row>
                <Row>
                    <p>A common problem we face as we age is an increased risk or fear of falling. Physiotherapy can help identify risks associated with falls and formulate an exercise program to educate individuals to help reduce falls.</p>
                </Row>
                </Col>
            </Row>
            <Row id={"Cancer"}>
                <Col className="room">
                <Row><h3>Cancer rehabilitation</h3></Row>
                <Row>
                <p>With expert knowledge to aid individuals with cancer or who are in remission, individualised exercise programs in strength and conditioning will help those recovering from cancer and the treatment thereof to gain more confidence in their life.</p>
                </Row>
                </Col>
                <Col>
                <img src="/images/Cancer_rehab1.jpg" alt="Cancer rehabilitation" width="100%" className="photo-effect"/>
                </Col>
            </Row>
            <Row id={"Casting_and_splinting"}>
                <Col>
                   <img src="/images/casting_v1.jpg" alt="arm cast" width="100%" className="photo-effect"/>
                </Col>
                <Col className="room">            
                <Row><h3>Casting and splinting</h3></Row>
                <Row>
                    <p>Following a significant injury that may lead to a fracture or a broken bone physiotherapy can aid in diagnosis and acute management of bone injury together with medical doctors and medical imaging.</p>
                    <p>With extensive experience in casting and splinting, we can apply a waterproof fiberglass cast or one of many splints, if appropriate, in managing your injury. Following an appropriate period of wear, we can remove your cast and assist you in restoration of full function.</p>
                    </Row>
                </Col>
            </Row>
            <Row id={"Dry_needling"}>
                <Col className="room">
                <Row><h3>Dry needling</h3></Row>
                <Row>
                    <p>Dry needling has evolved as a more scientifically supported version of acupunture. With use of the same needles and having a significant correlation in the positioning of the needles, dry needling aims to reduce pain and muscle tightness by targeting active tender points in muscles (trigger points).</p>
                </Row>
                </Col>
                <Col>
                   <img src="/images/dry_needling.jpg" alt="dry needling" width="100%" className="photo-effect"/>
                </Col>
            </Row>
            <Row id={"NDIS"}>
                <Col>
                   <img src="/images/I_heart_NDIS.jpg" alt="NDIS logo" width="100%" className="photo-effect"/>
                </Col>
                <Col className="room">
                <Row><h3>Disability (NDIS)</h3></Row>
                <Row>
                    <p>People living with a disability come with unique concerns. Physiotherapists are extensively trained in the array of conditions and disabilities. With this understanding they can help individuals live their best life.</p>
                </Row>
                </Col>
                </Row>
            <Row id={"DVA"}>
                <Col className="room">
                <Row><h3>DVA</h3></Row>
                <Row>
                    <p>Greater Potential physio is registered to help those with referrals from the Department of Veterans’ Affairs. If you would like an appointment and you are a veteran you will need to bring your referral and your white or gold card to show our staff.</p>
                </Row>
                </Col>
                <Col>
                   <img src="/images/defence1.jpg" alt="Department of veterans affairs" width="100%" className="photo-effect"/>
                </Col>
            </Row>
            <Row id={"Home_visit"}>
                 <Col>
                   <img src="/images/mobile_physio.jpg" alt="home visit" width="100%" className="photo-effect"/>
                   
                </Col>
                <Col className="room">
                <Row><h3>Home visits / Mobile physiotherapy</h3></Row>
                <Row>
                    <p>If you or a family member cannot make it to us due to injury or health concerns, we will come to you, making getting back on your feet easier. As we are registered health providers, rebates are still available for our services through private health. If you would like a home visit, please call to make a booking.</p>
                </Row>
                </Col>
            </Row>
            <Row  id={"MSK"}>
                <Col className="room">
                <Row><h3>Musculoskeletal</h3></Row>
                <Row>
                    <p>Physiotherapists have a unique skill set that allow for assessment, diagnosis and treatment for body aches, pains and injuries. Aches and pains can present in life for many different reasons and physiotherapy training allows our clinician to decipher each individual presentation and aid in managing and understanding of how to treat pain.</p>
                    <p>Back and neck pain are common troubles people suffer from throughout life but with the assistance of physiotherapy the frequency, intensity and duration of pain can be reduced.</p>
                    
                </Row>
                </Col>
                <Col>
                   <img src="/images/MSK1.jpg" alt="manual therapy" width="100%" className="photo-effect"/>
                   <p id={"orthopaedics"}></p>
                </Col>
            </Row>
            <Row>
                <Col>
                   <img src="/images/Bones.jpg" alt="hand bones" width="100%" className="photo-effect"/>
                </Col>
                <Col className="room">
                <Row><h3>Orthopaedics</h3></Row>
                <Row>
                    <p>If you are having a joint replacement or have suffered an injury that has lead to the need for surgical input. Physiotherapy can help you get back on your feet. Physiotherapists are also able to help you understand if surgery is indicated. We will communicate closely with any treating physicians or surgeons to make your recovery the smoothest possible.</p>
                    <p id={"paediatrics"}></p>
                </Row>
                </Col>
            </Row>
            <Row>
                <Col className="room">
                <Row><h3>Paediatrics</h3></Row>
                <Row>
                    <p>Physiotherapist are trained to understand the entire scope of life. Physiotherapy training allows us to help improve infant and children's development through harnessing the power of play and movement that suits the individual youngsters needs.</p>
                </Row>
                </Col>
                <Col>
                   <img src="/images/paeds_v2.jpg" alt="child sitting" width="100%" className="photo-effect"/>
                   <p id={"pain"}></p>
                </Col>
            </Row>
            <Row>
                <Col>
                <img src="/images/pain.jpg" alt="musculoskeletal pain" width="100%" className="photo-effect"/>
                </Col>
                <Col className="room">
                <Row><h3>Pain</h3></Row>
                <Row>
                    <p>Pain is a complex human experience that is different for everybody. We all have a variety of reasons why anyone going through the same experience can have completely different outcomes. Physiotherapists have comprehensive training in understanding and managing pain. </p>
                    <p>From dealing with new acute pains to sub-acute and chronic pain physiotherapists can aid in helping people manage and better understand pain.</p>
                    <p id={"rehab"}></p>
                </Row>
                </Col>
            </Row>
            <Row>
                <Col className="room">
                <Row><h3>Rehabilitation</h3></Row>
                <Row>
                    <p>Whether it be returning abdominal surgery, orthopaedic surgery, heart or lung surgery or neurological injury, physiotherapy can help get you back on your feet. Our training allows up to understand what is necessary and safe to get people back on their feet and returning to the life they want to live.</p>
                    <p>Our team will communicate closely with your medical professional and other members of your care team to ensure you have the smoothest recovery possible</p>
                </Row>
                </Col>
                <Col>
                <img src="/images/Rehabilitation_small.jpg" alt="injury and post-operative rehabilitation" width="100%" className="photo-effect"/> 
                </Col>
            </Row>
            <Row>
                <Col>
                <p id={"sports_injuries"}></p>
                <img src="/images/Tim_sports_v1.jpg" alt="Tim covering sport" width="100%" className="photo-effect"/>
                </Col>
                <Col className="room">
                <Row><h3>Sports physiotherapy</h3></Row>
                <Row>
                    <p>Every sport is different, and athletes require their own unique skills and abilities to perform at an optimal level within the sporting environment. Sports physiotherapists have a comprehensive understanding of biomechanics as well as activity and exercise load management. In addition to an in-depth understanding of tissue healing principles and diagnostics and treatment of musculoskeletal pathologies. All this gives us the unique ability in guiding return to training and play following injury.</p>
                    <p>Every injury is unique, and the mechanisms of injury are important to understand what recovery needs. Physiotherapists with an interest and understanding of sport are best suited in aiding your return as they understand the demands of athleticism and the sporting environment.</p>
                    <p id={"Strength_and_Conditioning"}></p>
                </Row>
                </Col>
            </Row>
            <Row>
                <Col className="room">
                <Row><h3>Strength and conditioning </h3></Row>
                <Row>
                    <p>Physiotherapists have comprehensive understanding of the demands needed to deal with sports and work life. Keeping ourselves strong is of great importance to maintains one’s wellbeing. The physiotherapy skill set allows us to help people preform at their peak, writing programs to develop strength, power, endurance agility an other specific skills require to an individuals chosen sport or activity. Our understanding of periodization to create programs that ensure programming meets that appropriate need of the season or performance cycle.</p>
                </Row>
                </Col>
                <Col>
                   <img src="/images/strength_condition.jpg" alt="weights and cones" width="100%" className="photo-effect"/>
                   <p></p>
                </Col>
            </Row>
            <Row>
                <Col>
                   <img src="/images/telehealth.jpg" alt="Internet consult" width="100%" className="photo-effect"/>
                </Col>   
                <Col className="room">
                <Row><h3 id={"tele"}>Telehealth</h3></Row>
                <Row>
                    <p>If you are remote and in need of assistance and advice returning from injury or are looking to understand how you can better manage your health when dealing with aches and pains or dealing with more complex health conditions reach out. Our telehealth services are services over the phone or through internet services that are rebatable with under health insurance. </p>
                    <p>A larger part of seeing improvement is knowing what to do and when, and what should be minimized. Hands on treatment although useful is not necessary to achieve goals. Our service can provide you with a treatment plan and exercise in a printable format to help you.</p>
                    <p id={"workcomp"}></p>
                </Row>
                </Col> 
            </Row>
            <Row>
                <Col className="room">
                <Row><h3>Worker compensation or work cover</h3></Row>
                <Row><p>If you are injured at work and in need of assistance in overcoming pain or injury and in restoring your ability to return to work Greater Potential Physio can help.</p>
                     <p>To Ensure your appointment is covered by your workplace insurance you need to ensure the injury has been reported to your workplace and you have seen a doctor and received a referral.</p>
                     <p>Ensure you bring any relevant paperwork to your appointment and are aware of any claim numbers provided by the insurer. This will allow billing to be performed directly to the insurer with each appointment</p>
                </Row>
                </Col>   
                <Col>
                   <img src="/images/workers_comp1.jpg" alt="Workers compensation care" width="100%" className="photo-effect"/>
                </Col> 
            </Row>
          </Container>
        </section>
    </div>    
    <Footer />
    </>
  );
}

export default Services;
