import './footer.css';
  
  function Footer() {
    return (
        <section className="footer">
        <p>
        &copy; Copyright 2024 Greater Potential Physio. All Rights Reserved.
        </p>
      </section>
    );
  }
  
  export default Footer;
  